import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Header, Input, Row, Button, useUIState, Grid, MoneyImage, RatioCol, ApolloEffect, modal, TouchField, HomeHeader } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, SITE_INFO, COLOR, IS_TIENNAMSINH_NET } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign, FontAwesome5, EvilIcons, MaterialIcons, Entypo, FontAwesome } from '@expo/vector-icons';
import { ASSETS } from 'assets';
import { FacebookProvider, Page, CustomChat } from 'react-facebook';
import SearchForm from './Home.SearchForm';
import { SubmitOrder } from './Home.SubmitOrder';
import { TimeHelper, VarHelper } from 'helpers';
import Swal from 'sweetalert2'

const showError = (message) => {
  Swal.fire({
    title: 'Có lỗi xảy ra!',
    text: message,
    icon: 'error',
    confirmButtonText: 'OK'
  })
}

const showAlert = message => {
  Swal.fire(
    'Thành công!',
    message,
    'success'
  )
}

const Home: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { ctv }: any = route.params || {};
  const [ngaythangnamsinh, setNgaythangnamsinh] = useState(['', undefined, '']);
  const [yearPairs, setYearPairs] = useState(['', '']);
  const [listSearch, setListSearch] = useState([]);
  const [carts, setCarts] = useState([]);
  const [message, setMessage] = useState('');
  const [tinyUrl, setTinyUrl] = useState('');
  const [utm_source, setUTMSource] = useState('facebook-non-cpas');
  const [utm_campaign, setUTMCampain] = useState('SOC_YBR_FB_POS_UNK______N._K.DONG GIA X9K_D.622222_S._P._A.');

  const listQuyUoc = useRef([]);
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const [{ loading: form1Loading }, setForm1UI] = useUIState();
  const [{ loading: form2Loading }, setForm2UI] = useUIState();

  useEffect(() => {
    setTimeout(() => {
      const iframeEl = document.querySelector('iframe');
      if (iframeEl) iframeEl.remove();
    }, 2000);
  }, []);

  useEffect(() => {
    fetch('https://data.phimhaymoituan.com/api/v1/db/data/v1/AZVN TinyUrl/Settings/1', {
      method: 'GET',
      headers: { 'xc-token': '9JccuFXL7JhzJGTSXUWoOKecv2os63UXNRDaWwM7' },
    }).then(res => res.json())
    .then(json => {
      if (json.Id) {
        setUTMSource(json.utm_source);
        setUTMCampain(json.utm_campaign);
      }
    });
  }, []);

  const genTracking = url => {
    return url + `${url.includes('?') ? '&' : '?'}utm_source=${utm_source}&utm_medium=social&utm_campaign=${utm_campaign}`;
  };

  const traCuu = async () => {
    console.log(ngaythangnamsinh);
    if (!ngaythangnamsinh[0]) return alert('Vui lòng nhập link');
    const url = genTracking(ngaythangnamsinh[0]);
    setForm1UI({ loading: true });
    await TimeHelper.wait(300);
    const res = await fetch('https://api.tinyurl.com/create?api_token=pLyslN14zl94EJf5m0hCRBDI4yHkeumKTBLDzKrjYWOq2kp1v1gofU3anrwI', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "url": url,
        "alias": ngaythangnamsinh[1] || undefined,
      }),
    });
    const json = await res.json();
    setForm1UI({ loading: false });
    if (json.errors.length > 0) return alert (json.errors.join(', '));
    if (json.data && json.data.tiny_url) {
      setNgaythangnamsinh(['', '', '']);
      setTinyUrl(json.data.tiny_url);
    }
  }

  const onTraCuuCapDoi = async () => {
    setForm2UI({ loading: true });
    await TimeHelper.wait(300);
    setForm2UI({ loading: false });
  }

  const renderMainContent = () => {
    return (
      <Col flex1 justifyContent='center' backgroundColor={'#E9E9F0'}>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <Col flex1 p2 middle>
            <SearchForm
              ngaythangnamsinh={ngaythangnamsinh}
              setNgaythangnamsinh={setNgaythangnamsinh}
              onTraCuu={traCuu}
              onTraCuuCapDoi={onTraCuuCapDoi}
              form1Loading={form1Loading}
              yearPairs={yearPairs}
              setYearPairs={setYearPairs}
              form2Loading={form2Loading}
              ctv={ctv}
              tinyUrl={tinyUrl}
              utm_source={utm_source}
              setUTMSource={setUTMSource}
              setUTMCampain={setUTMCampain}
              utm_campaign={utm_campaign}
            />
          </Col>
          
        </ScrollView>
      </Col>
    )
  };

  return renderMainContent();
}



Home.routeInfo = {
  title: SITE_INFO.NAME,
  path: '/',
};

export default Home;