import { Col, Text, Header, Input, Row, Button, TouchField, Grid } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, SITE_INFO, COLOR } from 'const';
import { ActivityIndicator, Image } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign, Feather, FontAwesome, Entypo } from '@expo/vector-icons';
import { ASSETS } from 'assets';
import Select from 'react-select';

const SearchForm = ({
  ngaythangnamsinh, setNgaythangnamsinh,
  onTraCuu,
  form1Loading,
  yearPairs, setYearPairs,
  form2Loading,
  onTraCuuCapDoi,
  ctv,
  tinyUrl,
  utm_source,
  utm_campaign,
  setUTMSource,
  setUTMCampain,
}) => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const circleSize = 60;

  const renderInstruction = () => {
    const steps = [
      'Điền \nngày tháng',
      'Ấn \ntra cứu',
      'Chọn \ntờ tiền',
      'Đặt hàng',
    ];
    // const color = SITE_INFO.FROM_BRAND_COLOR(100);
    const color = 'rgba(0,0,0,0.1)';
    return (
      <Row justifyContent={'space-between'} mb2>
        {steps.map((val, i) => {
          return (
            <React.Fragment key={'in-' + i}>
              <Col m0 width={circleSize} height={circleSize} borderRadius={circleSize / 2} backgroundColor={color} middle>
                <Text center fontSize={10}>{val}</Text>
              </Col>
              {Boolean(i !== (steps.length - 1) && breakpoint !== 'xs') && (
                <AntDesign name="right" size={30} color={color} />
              )}
            </React.Fragment>
          );
        })}
      </Row>
    )
  }

  return (
    <Col backgroundColor={'white'} shadow round1 width={rV({ xs: '100%', md: '50%' })} maxWidth={500} overflow='hidden'>
      <Col height={140}>
        <Col absoluteFill>
          <Image source={SITE_INFO.COVER ? { uri: SITE_INFO.COVER } : ASSETS.MONEY_BG} style={{ width: '100%', height: '100%' }} resizeMode='cover' />
        </Col>
        <Col absoluteFill backgroundColor={'rgba(0,0,0,0.5)'} middle>
          <Text center h4 mb1 pv0 ph1 borderRadius={15} backgroundColor={'white'} color={SITE_INFO.BRAND_COLOR}
            fontSize={SITE_INFO.NAME.length < 50 ? undefined : 13}
          >{SITE_INFO.NAME}</Text>
          <Text center caption color={'white'} >{SITE_INFO.DESCRIPTION}</Text>
          {!!SITE_INFO.ADDRESS && (
            <Row>
              <Entypo name="location" size={16} color="white" />
              <Col flex1 ml0>
                <Text center caption color={'white'} numberOfLines={1}>{SITE_INFO.ADDRESS}</Text>
              </Col>
            </Row>
          )}
        </Col>

        {!!user && (
          <Col absolute top={10} right={10}>
            <TouchField
              bgHovered='rgba(0,0,0,0.5)'
              onPress={() => navigate(SCREEN.Admin)}
            >
              <Col p0 ph1 round1 backgroundColor={'rgba(255,255,255,0.4)'}>
                <Text color='white'>Admin</Text>
              </Col>
            </TouchField>
          </Col>
        )}
      </Col>
      <Col p2>
        {/* {renderInstruction()} */}

        <Col>
          <Text caption mb1>Nhập <Text bold color={SITE_INFO.BRAND_COLOR}>link cần tạo tracking</Text></Text>
          <Row mb1>
            <Grid flex1 xs='2:3:2' md='1:.'>
              <Col mr0>
                <Input
                  value={ngaythangnamsinh[0]}
                  onChange={newVal => {
                    const newNgay = ngaythangnamsinh.slice();
                    newNgay[0] = newVal;
                    setNgaythangnamsinh(newNgay);
                  }}
                  placeholder=''
                  inputProps={{
                    style: {
                      fontSize: rV({ xs: 12, md: 14 }),
                      paddingHorizontal: rV({ xs: 5, md: 15 }),
                    }
                  }}
                />
              </Col>
            </Grid>
          </Row>
          {/* <Text caption mb1>Nhập <Text bold color={SITE_INFO.BRAND_COLOR}>utm_source</Text></Text>
          <Row mb1>
            <Grid flex1 xs='2:3:2' md='1:.'>
              <Col mr0>
                <Input
                  value={utm_source}
                  onChange={newVal => {
                    setUTMSource(newVal);
                  }}
                  placeholder=''
                  inputProps={{
                    style: {
                      fontSize: rV({ xs: 12, md: 14 }),
                      paddingHorizontal: rV({ xs: 5, md: 15 }),
                    }
                  }}
                />
              </Col>
            </Grid>
          </Row>
          <Text caption mb1>Nhập <Text bold color={SITE_INFO.BRAND_COLOR}>utm_campaign</Text></Text>
          <Row mb1>
            <Grid flex1 xs='2:3:2' md='1:.'>
              <Col mr0>
                <Input
                  value={utm_campaign}
                  onChange={newVal => {
                    setUTMCampain(newVal);
                  }}
                  placeholder=''
                  inputProps={{
                    style: {
                      fontSize: rV({ xs: 12, md: 14 }),
                      paddingHorizontal: rV({ xs: 5, md: 15 }),
                    }
                  }}
                />
              </Col>
            </Grid>
          </Row> */}
          <Text caption mb1>Nhập <Text bold color={SITE_INFO.BRAND_COLOR}>alias</Text> (optional)</Text>
          <Row mb1>
            <Grid flex1 xs='2:3:2' md='1:.'>
              <Col mr0>
                <Input
                  value={ngaythangnamsinh[1]}
                  onChange={newVal => {
                    const newNgay = ngaythangnamsinh.slice();
                    newNgay[1] = newVal;
                    setNgaythangnamsinh(newNgay);
                  }}
                  placeholder=''
                  inputProps={{
                    style: {
                      fontSize: rV({ xs: 12, md: 14 }),
                      paddingHorizontal: rV({ xs: 5, md: 15 }),
                    }
                  }}
                />
              </Col>
            </Grid>
            <Button
              isLoading={form1Loading}
              text='Tạo link'
              iconLeft={(
                <Feather name="link" size={20} color="white" />
              )}
              width={100}
              height={40}
              ml0
              borderRadius={20}
              backgroundColor={SITE_INFO.BRAND_COLOR}
              onPress={onTraCuu}
            />
          </Row>

          {tinyUrl && (
            <>
              <Text caption mb1>Đây là <Text bold color={SITE_INFO.BRAND_COLOR}>link</Text> đã tạo</Text>
              <Row mb1>
                <Grid flex1 xs='2:3:2' md='1:.'>
                  <Col mr0>
                    <Input
                      value={tinyUrl}
                      onChange={newVal => {
                        
                      }}
                      placeholder=''
                      inputProps={{
                        editable: false,
                        style: {
                          fontSize: rV({ xs: 12, md: 14 }),
                          paddingHorizontal: rV({ xs: 5, md: 15 }),
                        }
                      }}
                    />
                  </Col>
                </Grid>
                {/* <Button
                  isLoading={form1Loading}
                  text='Tạo link'
                  iconLeft={(
                    <Feather name="link" size={20} color="white" />
                  )}
                  width={100}
                  height={40}
                  ml0
                  borderRadius={20}
                  backgroundColor={SITE_INFO.BRAND_COLOR}
                  onPress={onTraCuu}
                /> */}
              </Row>
            </>
          )}
        </Col>
      </Col>

    </Col>
  )
};
export default SearchForm;