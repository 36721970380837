import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Header, Input, Row, Button, useUIState, Grid, MoneyImage, RatioCol, ApolloEffect, modal, TouchField, HomeHeader } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, SITE_INFO, COLOR, IS_TIENNAMSINH_NET } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign, FontAwesome5, EvilIcons, MaterialIcons, Entypo, FontAwesome } from '@expo/vector-icons';
import { ASSETS } from 'assets';
import { FacebookProvider, Page, CustomChat } from 'react-facebook';
import AdminForm from './Home.AdminForm';
import { SubmitOrder } from './Home.SubmitOrder';
import { TimeHelper, VarHelper } from 'helpers';
import Swal from 'sweetalert2'
const md5 = require('md5');

const showError = (message) => {
  Swal.fire({
    title: 'Có lỗi xảy ra!',
    text: message,
    icon: 'error',
    confirmButtonText: 'OK'
  })
}

const showAlert = message => {
  Swal.fire(
    'Thành công!',
    message,
    'success'
  )
}

const Home: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { ctv }: any = route.params || {};
  const [ngaythangnamsinh, setNgaythangnamsinh] = useState(['', undefined, '']);
  const [yearPairs, setYearPairs] = useState(['', '']);
  const [listSearch, setListSearch] = useState([]);
  const [carts, setCarts] = useState([]);
  const [message, setMessage] = useState('');
  const [tinyUrl, setTinyUrl] = useState('');
  const [utm_source, setUTMSource] = useState('');
  const [utm_campaign, setUTMCampain] = useState('');
  const [password, setPassword] = useState(localStorage.getItem('p') || '');
  useEffect(() => {
    localStorage.setItem('p', password);
  }, [password]);

  const listQuyUoc = useRef([]);
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const [{ loading: form1Loading }, setForm1UI] = useUIState();
  const [{ loading: form2Loading }, setForm2UI] = useUIState();

  useEffect(() => {
    setTimeout(() => {
      const iframeEl = document.querySelector('iframe');
      if (iframeEl) iframeEl.remove();
    }, 2000);
  }, []);

  useEffect(() => {
    fetch('https://data.phimhaymoituan.com/api/v1/db/data/v1/AZVN TinyUrl/Settings/1', {
      method: 'GET',
      headers: { 'xc-token': '9JccuFXL7JhzJGTSXUWoOKecv2os63UXNRDaWwM7' },
    }).then(res => res.json())
    .then(json => {
      if (json.Id) {
        setUTMSource(json.utm_source);
        setUTMCampain(json.utm_campaign);
      }
    });
  }, []);

  const traCuu = async () => {
    if (md5(password) !== '97d0233d0ecc6eaa3e421f6855c9e7cf') return alert('Sai password');
    setForm1UI({ loading: true });
    await TimeHelper.wait(300);
    
    const res = await fetch('https://data.phimhaymoituan.com/api/v1/db/data/v1/AZVN TinyUrl/Settings/1', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'xc-token': '9JccuFXL7JhzJGTSXUWoOKecv2os63UXNRDaWwM7' },
        body: JSON.stringify({
          "utm_source": utm_source,
          "utm_campaign": utm_campaign,
        })
      });
    const json = await res.json();
    setForm1UI({ loading: false });
    if (!!json.Id) return alert ('Thành công');
  }

  const onTraCuuCapDoi = async () => {
    setForm2UI({ loading: true });
    await TimeHelper.wait(300);
    setForm2UI({ loading: false });
  }

  const renderMainContent = () => {
    return (
      <Col flex1 justifyContent='center' backgroundColor={'#E9E9F0'}>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <Col flex1 p2 middle>
            <AdminForm
              ngaythangnamsinh={ngaythangnamsinh}
              setNgaythangnamsinh={setNgaythangnamsinh}
              onTraCuu={traCuu}
              onTraCuuCapDoi={onTraCuuCapDoi}
              form1Loading={form1Loading}
              yearPairs={yearPairs}
              setYearPairs={setYearPairs}
              form2Loading={form2Loading}
              ctv={ctv}
              tinyUrl={tinyUrl}
              utm_source={utm_source}
              setUTMSource={setUTMSource}
              setUTMCampain={setUTMCampain}
              utm_campaign={utm_campaign}
              password={password}
              setPassword={setPassword}
            />
          </Col>
          
        </ScrollView>
      </Col>
    )
  };

  return renderMainContent();
}



Home.routeInfo = {
  title: SITE_INFO.NAME,
  path: '/admin',
};

export default Home;